import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import $ from "jquery"
import { useI18next } from "gatsby-plugin-react-i18next"

export default function Galeri({ data }) {
  const [kategoriler, setKategoriler] = useState([])
  const [galeriler, setGaleriler] = useState([])
  const { language } = useI18next()

  useEffect(() => {
    var toc = $(".toc")

    $(".nav input").click(function () {
      $(this).parent().toggleClass("mobilMenuAcik");
      $(this).parent().parent().parent("header").toggleClass("mobilMenuAcik")
    })

    $(window).scroll(function () {
      if ($(window).scrollTop() > 50) {
        toc.addClass("scrolled")
      } else {
        toc.removeClass("scrolled")
      }
    })
    if (data) {
      const cats = data?.kategoriler?.edges.map(edge => edge.node.frontmatter)
      let dogs = data?.galeri?.edges?.map(edge => edge.node.frontmatter)
      dogs = dogs?.map(dog => {
        const cat = cats?.find(cat => cat.title === dog.kategori)
        return {
          kategori_resmi: cat?.kategori_image,
          galeri_sirasi: cat?.galeri_sirasi,
          ...dog,
        }
      })
      setKategoriler(cats)
      setGaleriler(
        dogs.sort((a, b) => {
          return a.galeri_sirasi > b.galeri_sirasi ? 1 : -1
        })
      )
    }
  }, [data])

  return (
    <Layout>
      <SEO title="Galeri" />
      <div className="gallery-container">
        <div className="gallery-all">
          {galeriler?.map((galeri, i) => {
            const kat = kategoriler.find(k => k.title === galeri.kategori)
            const kategori = language === "en" ? kat?.title_en : kat?.title
            return (
              <div className="gallery" key={i}>
                <div
                  className="gallery-title"
                  id={galeri.kategori.replace(/ /g, "-")}
                >
                  <img src={galeri.kategori_resmi} alt={kategori} />
                  {kategori}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper key={`wrapper` + galeri.kategori}>
                    {galeri.kategori_resimleri.map((kg, i) => {
                      return (
                        <a href={kg.resim} key={kg + i}>
                          <img
                            src={kg.resim}
                            alt={language === "en" ? kg.baslik_en : kg.baslik}
                          />
                        </a>
                      )
                    })}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            )
          })}
        </div>
        <div className="toc">
          {kategoriler.map((kategori, i) => {
            const handleClick = () => {
              const el = document.getElementById(
                kategori?.title?.replace(/ /g, "-")
              )
              el && el.scrollIntoView({ behavior: "smooth" })
            }
            const title = language === "en" ? kategori.title_en : kategori.title
            return (
              <a className="goCollection" onClick={handleClick} key={title}>
                {title}
                <img src={kategori.kategori_image} alt={title} />
              </a>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Galeri {
    kategoriler: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri_kategori" } } }
      sort: { order: ASC, fields: frontmatter___galeri_sirasi }
    ) {
      edges {
        node {
          frontmatter {
            galeri_sirasi
            kategori_image
            title
            title_en
          }
        }
      }
    }
    galeri: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri" } } }
    ) {
      edges {
        node {
          frontmatter {
            kategori
            kategori_resimleri {
              resim
              baslik
              baslik_en
            }
          }
        }
      }
    }
  }
`
